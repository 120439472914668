import React, { useState } from "react";

const AboutUs = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    phone: "",
    suggestion: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const submissionData = {
      type: "Suggest Us",
      fullName: formData.fullName,
      phone: formData.phone,
      purpose: formData.suggestion,
    };

    console.log("Submitting data:", submissionData);

    try {
      console.log(
        "Sending request to:",
        "https://abo7g4oez1.execute-api.ap-south-1.amazonaws.com/Prod/join"
      );
      const response = await fetch(
        "https://abo7g4oez1.execute-api.ap-south-1.amazonaws.com/Prod/join",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(submissionData),
        }
      );

      console.log("Response status:", response.status);
      console.log(
        "Response headers:",
        Object.fromEntries(response.headers.entries())
      );

      const responseText = await response.text();
      console.log("Raw response:", responseText);

      let result;
      try {
        result = JSON.parse(responseText);
      } catch (parseError) {
        console.error("Error parsing response:", parseError);
        result = { message: responseText };
      }

      if (response.ok) {
        console.log("Success response:", result);
        alert(result.message || "Suggestion submitted successfully");
        setFormData({
          fullName: "",
          phone: "",
          suggestion: "",
        });
      } else {
        console.error("Error response:", result);
        alert(`Error: ${result.message || "Unknown error occurred"}`);
      }
    } catch (error) {
      console.error("Fetch error:", error);
      alert(`Error submitting suggestion: ${error.message}`);
    }
  };

  const teamMembers = [
    {
      name: "Jagan Mohan Pallela",
      education: "B.Tech in Computer Science, AP-IIIT",
      profileImage:
        "https://bytehearts-home.s3.ap-south-1.amazonaws.com/Profiles/logo_profile.jpg",
      designation: "Founder & Product Developer",
      description:
        "Tech leader passionate about leveraging AI and machine learning for innovative product strategies.",
      linkedin: "https://www.linkedin.com/in/jaganmohan-pallela",
      // twitter: "https://twitter.com/janedoe",
      expertise:
        "Application Development, AI, Machine Learning, Product Strategy",
    },
    {
      name: "Praveen Chinthapulusu",
      education: "B.Tech in Computer Science, AP-IIIT",
      profileImage:
        "https://bytehearts-home.s3.ap-south-1.amazonaws.com/Profiles/prave_en.jpg",
      designation: "Software Developer",
      description:
        "Full-stack developer driving technical innovation with expertise in cloud architecture and AWS.",
      linkedin: "https://www.linkedin.com/in/praveen-kumar-reddy-chinthapulusu-478676253/",
      // twitter: "https://twitter.com/johnsmith",
      expertise: "Full-Stack Development, Cloud Architecture, AWS",
    },
    {
      name: "Nanda Kumar Pallela",
      education: "B.Tech in Artificial Intelligence, NIET",
      profileImage:
        "https://bytehearts-home.s3.ap-south-1.amazonaws.com/Profiles/nan_da.jpg",
      designation: "Predictive Model Designer",
      description:
        " Expert in predictive modeling and AI, delivering personalized and effective solutions.",
      linkedin: "https://www.linkedin.com/in/pallela-nanda-kumar-264639252",
      // twitter: "https://twitter.com/emilychen",
      expertise:
        "Predictive Modeling, Machine Learning,Artificial Intelligence",
    },
    {
      name: "Boga Shravya",
      education: "B.Tech in Computer Science, IIIT-Basar",
      profileImage:
        "https://bytehearts-home.s3.ap-south-1.amazonaws.com/Profiles/shravy_a.jpg",
      designation: "Product Designer",
      description:
        "Creative designer focusing on intuitive, visually appealing user interfaces",
      linkedin: "https://www.linkedin.com/in/shravyaboga",
      // twitter: "https://twitter.com/michaeljohnson",
      expertise: "UX/UI Design,Prototyping, Visual Design, User Research",
    },
    // {
    //   name: "name-5",
    //   education: "MS in Data Science, UC Berkeley",
    //   designation: "Head of Data Science",
    //   description: "Sarah leads our data science initiatives, transforming raw data into actionable insights that drive our product development and business decisions.",
    //   linkedin: "https://www.linkedin.com/in/sarahkim",
    //   twitter: "https://twitter.com/sarahkim",
    //   expertise: "Data Analytics, Machine Learning, Predictive Modeling"
    // }
  ];

  return (
    <div className="flex flex-col min-h-screen bg-gray-900 text-white mt-[80px] pb-[66px]">
      <div className="flex-1 p-4 md:p-4 lg:p-8 mx-auto max-w-7xl">
        <h1 className="text-4xl font-bold mb-6 text-blue-500" id="about">
          About Us
        </h1>
        <p className="mb-12 text-lg">
          At ByteHearts, we're on a mission to revolutionize productivity
          through personalized digital experiences. Our team of visionaries and
          tech enthusiasts combines cutting-edge technologies with
          human-centered design to create products that adapt and grow with each
          user's unique journey.
        </p>

        <h2 className="text-3xl font-bold mb-4 text-blue-500" id="team">
          Meet Our Team
        </h2>
        <p className="mb-8 text-lg">
          We're a diverse group of passionate individuals working together to
          create amazing experiences.
        </p>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-16">
          {teamMembers.map((member, index) => (
            <div key={index} className="bg-gray-800 p-6 rounded-lg shadow-md">
              <div className="flex items-center mb-4">
                <img
                  src={member.profileImage}
                  alt={`${member.name}'s profile`}
                  className="w-14 h-14 rounded-2xl mr-4"
                />
                <div>
                  <h3 className="text-xl font-bold mb-1 text-blue-400">
                    {member.name}
                  </h3>
                  <p className="text-sm text-gray-400">{member.education}</p>
                </div>
              </div>
              <p className="text-md font-semibold mb-2 text-blue-300">
                {member.designation}
              </p>
              <p className="mb-4 text-gray-300">{member.description}</p>
              <p className="text-sm font-semibold mb-3 text-blue-200">
                Expertise: {member.expertise}
              </p>
              <div className="flex space-x-4">
                <a
                  href={member.linkedin}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 hover:text-blue-300 flex items-center"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    data-supported-dps="24x24"
                    fill="currentColor"
                    className="w-4 h-4 mr-2"
                    focusable="false"
                  >
                    <path d="M20.5 2h-17A1.5 1.5 0 002 3.5v17A1.5 1.5 0 003.5 22h17a1.5 1.5 0 001.5-1.5v-17A1.5 1.5 0 0020.5 2zM8 19H5v-9h3zM6.5 8.25A1.75 1.75 0 118.3 6.5a1.78 1.78 0 01-1.8 1.75zM19 19h-3v-4.74c0-1.42-.6-1.93-1.38-1.93A1.74 1.74 0 0013 14.19a.66.66 0 000 .14V19h-3v-9h2.9v1.3a3.11 3.11 0 012.7-1.4c1.55 0 3.36.86 3.36 3.66z"></path>
                  </svg>
                  LinkedIn
                </a>
              </div>
            </div>
          ))}
        </div>

        <div id="suggest-us-section" className="flex flex-col space-y-8">
          {/* Suggest to Us section with background */}
          <div>
            <h2
              className="text-3xl font-bold mb-4 text-blue-500"
              id="suggestus"
            >
              Suggest Us
            </h2>
            <p className="text-lg text-gray-300">
              We highly value your feedback, as your ideas help us grow and
              evolve. Whether you have a new feature suggestion, recommendations
              for improving our current products, or other insights on how we
              can better meet your needs, we're always listening.
            </p>
          </div>

          <div className="bg-gray-800 p-2 md:p-2 lg:p-8 rounded-lg shadow-md">
            <div className="flex flex-col justify-between md:flex-row gap-8">
              <div className="w-full md:w-1/2">
                <h2 className="text-2xl font-bold mb-4 text-blue-500">
                  We'd love to know your ideas!
                </h2>
                <p className="mb-6 text-lg">
                  We value your input! Your suggestions help us improve and
                  innovate. Whether it's a new feature idea, a way to enhance
                  our existing products, or any other feedback, we're all ears.
                </p>
                <ul className="list-disc list-inside space-y-2 text-gray-300">
                  <li>Be specific and detailed in your suggestion</li>
                  <li>Explain how your idea could benefit users</li>
                  <li>Feel free to share examples or use cases</li>
                  <li>Don't hesitate to think outside the box</li>
                  <li>Consider both short-term and long-term impacts</li>
                </ul>
              </div>

              <div className="w-full md:w-2/5 text-white bg-gray-900 p-6 rounded-lg">
                <h2 className="text-2xl font-bold mb-4">Share Your Ideas</h2>
                <form className="space-y-2" onSubmit={handleSubmit}>
                  <div>
                    <label
                      htmlFor="fullName"
                      className="block text-sm font-medium mb-1"
                    >
                      Full Name
                    </label>
                    <input
                      type="text"
                      id="fullName"
                      name="fullName"
                      value={formData.fullName}
                      onChange={handleChange}
                      className="w-full px-3 py-2 border text-white bg-gray-900 border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                      required
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="phone"
                      className="block text-sm font-medium mb-1"
                    >
                      Mobile Number
                    </label>
                    <input
                      type="tel"
                      id="phone"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      className="w-full px-3 py-2 border bg-gray-900 border-gray-600 rounded-md text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                      required
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="suggestion"
                      className="block text-sm font-medium mb-1"
                    >
                      Your Suggestion
                    </label>
                    <textarea
                      id="suggestion"
                      name="suggestion"
                      rows="4"
                      value={formData.suggestion}
                      onChange={handleChange}
                      className="w-full px-3 py-2 border bg-gray-900 border-gray-600 rounded-md text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                      required
                    ></textarea>
                  </div>
                  <button
                    type="submit"
                    className="w-full px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition duration-150 ease-in-out"
                  >
                    Submit Suggestion
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
