import React, { useEffect } from 'react'
import MainContent from './MainContent'
import Mainpage from './Mainpage'
import Milestones from './Milestones'
import OurMotives from './Motives'
import Products from './Products'
import FAQ from './FAQ'
import WhyBytehearts from './WhyByteHearts'

const Pages = () => {
  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);
  return (
    <div className='w-full'>
    <Mainpage />
      <MainContent />
      <OurMotives />
      <WhyBytehearts/>
      <FAQ/>
      <Milestones />
    </div>
  )
}

export default Pages