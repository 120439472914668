import React from 'react';

const BlogPost = ({ title, description, date, imageUrl }) => (
  <div className="bg-white rounded-lg overflow-hidden shadow-lg transition-transform duration-300 hover:scale-105">
    <img className="w-full h-48 object-cover" src={imageUrl} alt={title} />
    <div className="p-6">
      <h3 className="font-bold text-xl mb-2 text-gray-800">{title}</h3>
      <p className="text-gray-600 text-sm mb-4">{description}</p>
      <p className="text-blue-500 text-xs">{date}</p>
    </div>
  </div>
);

const dummyData = [
  {
    id: 1,
    title: "Lessons from the Cloud: How Video Cloud Platforms Will Redefine Learning and Training",
    description: "Discover how a video cloud platform can elevate learning experiences.",
    date: "Jun 8, 2023",
    imageUrl: "https://picsum.photos/seed/learn/800/600"
  },
  {
    id: 2,
    title: "MICE: Not Mousing Around Meetings in Hybrid Events Today",
    description: "Uncover the transformative potential of video cloud solutions in enhancing hybrid events.",
    date: "May 25, 2023",
    imageUrl: "https://picsum.photos/seed/mice/800/600"
  },
  {
    id: 3,
    title: "Bridging the Gap: How Video Delivery Solutions Can Enhance Cloud Gaming Experiences",
    description: "Discover the benefits of implementing video delivery solutions for cloud gaming services.",
    date: "May 19, 2023",
    imageUrl: "https://picsum.photos/seed/gaming/800/600"
  }
];

const App = () => {
  return (
    <div className="bg-gray-100 min-h-screen p-8">
      <div className="max-w-6xl mx-auto">
        <h1 className="text-4xl font-bold text-center text-gray-800 mb-4">Our Blogs</h1>
        <p className="text-xl text-center text-gray-600 mb-12">Stay updated with the latest insights and trends</p>
        
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {dummyData.map((post) => (
            <BlogPost
              key={post.id}
              title={post.title}
              description={post.description}
              date={post.date}
              imageUrl={post.imageUrl}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default App;