import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Mainpage from './components/Mainpage';
import BlogsPage from './components/Blogs';
import Pages from './components/Pages';
import AboutUs from './components/AboutUs';
import JoinUs from './components/JoinUs';
import Products from './components/Products';



const appRouter = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children:[
      {
        path : "/",
        element :<Pages />,
      },
      {
        path:"/aboutus",
        element:<AboutUs />,
      },
      {
        path:"/joinus",
        element:<JoinUs />,
      },
      {
        path:"/blogs",
        element:<BlogsPage />,
      },
      {
        path:"/products",
        element:<Products />,
      }
    ]
  }
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <RouterProvider router={appRouter}/>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
