import React from "react";
import { Link, useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();

  const handleNavigation = (e, id) => {
    e.preventDefault();
    navigate("/aboutus");
    setTimeout(() => {
      const target = document.getElementById(id);
      if (target) {
        target.scrollIntoView({ behavior: "smooth" });
      }
    }, 100);
  };

  return (
    <footer className="bg-[#314bc2] text-white py-8 font-sans">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-8 mb-8">
          <div className="col-span-1 lg:col-span-2">
            <div
              className="flex items-center justify-center md:justify-start cursor-pointer gap-[2px] mb-4"
              onClick={() => navigate("/")}
            >
              <svg
                width="30"
                height="30"
                viewBox="0 0 143 144"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M31 36V111C20.6 115.4 2 103 1.5 95V36C13.9 -5.19999 46 -1.49999 60.5 5.50001C37.7 4.70001 31.3333 25.5 31 36Z"
                  fill="white"
                  stroke="white"
                />
                <path
                  d="M21.0004 114.5C17.5004 114.5 1.50039 108 1.50039 103C-3.29961 129.8 15.8337 140.833 26.0004 143H106.5C125.3 140.2 135.334 126.167 138 119.5C138.8 99.5 120.667 93.8333 111.5 93.5C117 107 108.667 111 104.5 114.5H21.0004Z"
                  fill="white"
                  stroke="white"
                />
                <path
                  d="M66.5 8.50002C47.5 5 38.1667 22 35.5 31C47.5 27.8 54.1667 34 56 37.5V88.5H106.5C128.9 88.9 139.5 103.667 142 111C142.8 71.8 118.667 60.3334 106.5 59.5H85V36.5C81.4 22.1 71.5 10.5 66.5 8.50002Z"
                  fill="white"
                  stroke="white"
                />
              </svg>
              <div className="text-3xl mt-[6px] font-bold">yteHearts</div>
            </div>
            <p className="text-sm text-gray-200 mt-2">
              At ByteHearts, we're on a mission to revolutionize productivity
              through personalized digital experiences. Our team of visionaries
              and tech enthusiasts combines cutting-edge technologies with
              human-centered design to create products that adapt and grow with
              each user's unique journey.
            </p>
          </div>

          <div>
            <h3 className="text-lg font-semibold mb-4">About Us</h3>
            <ul className="space-y-2 text-sm">
              <Link to="/aboutus">
                <li className="hover:text-gray-300 transition pb-2">Company</li>
              </Link>

              <Link to="/aboutus">
                <li className="hover:text-gray-300 transition pb-2">
                  Our team
                </li>
              </Link>
              <Link to="/aboutus">
              <li
                onClick={(e) => handleNavigation(e, "suggest-us-section")}
                className="hover:text-gray-300 transition pb-2"
              >
                Suggest Us
              </li>
              </Link>
            </ul>
          </div>

          <div>
            <h3 className="text-lg font-semibold mb-4">Products</h3>
            <ul className="space-y-2 text-sm">
              <li
                onClick={() => {
                  navigate("/products");
                }}
                className="cursor-pointer hover:text-gray-300 transition"
              >
                Product-A
              </li>
              <li
                onClick={() => {
                  navigate("/products", { state: { modal: "modal" } });
                }}
                className="cursor-pointer hover:text-gray-300 transition"
              >
                Be a Sponsor ↗
              </li>
            </ul>
          </div>

          <div>
            <h3 className="text-lg font-semibold mb-4">Contact Us</h3>
            <ul className="space-y-2 text-sm">
              <li>
                <a
                  href="tel:+917981112753"
                  className="hover:text-gray-300 transition"
                >
                  +91 798111 2753
                </a>
              </li>
              <li>
                <a
                  href="mailto:hello@bytehearts.com"
                  className="hover:text-gray-300 transition"
                >
                  hello@bytehearts.com
                </a>
              </li>
              <li>
                <a href="#" className="hover:text-gray-300 transition">
                  Join ByteHearts ↗
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="border-t border-gray-700 pt-8 mt-8 text-sm text-center sm:text-left">
          <p>© 2024 ByteHearts. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
