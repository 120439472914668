import React, { useState, useEffect } from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { Outlet } from "react-router-dom";

const App = () => {
  const [scrolled, setScrolled] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [scrollDirection, setScrollDirection] = useState("up");

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (currentScrollY > lastScrollY && currentScrollY >= 750) {
        setScrollDirection("down");
        setScrolled(true);
      } else if (currentScrollY < lastScrollY) {
        setScrollDirection("up");
        setScrolled(false);
      }

      setLastScrollY(currentScrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY]);

  return (
    <div className="overflow-x-hidden [&::-webkit-scrollbar]:hidden">
      <Header scrolled={scrolled} />
      <Outlet />
      <Footer />
    </div>
  );
};

export default App;
