import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const Products = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    fullName: "",
    phone: "",
    whatsapp: false,
    email: "",
    purpose: "",
  });

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const submissionData = {
      type: "Sponsor",
      fullName: formData.fullName,
      phone: formData.phone,
      whatsapp: formData.whatsapp,
      email: formData.email || null,
      purpose: formData.purpose,
    };

    console.log("Submitting data:", submissionData);

    try {
      console.log(
        "Sending request to:",
        "https://abo7g4oez1.execute-api.ap-south-1.amazonaws.com/Prod/join"
      );
      const response = await fetch(
        "https://abo7g4oez1.execute-api.ap-south-1.amazonaws.com/Prod/join",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(submissionData),
        }
      );

      console.log("Response status:", response.status);
      console.log(
        "Response headers:",
        Object.fromEntries(response.headers.entries())
      );

      const responseText = await response.text();
      console.log("Raw response:", responseText);

      let result;
      try {
        result = JSON.parse(responseText);
      } catch (parseError) {
        console.error("Error parsing response:", parseError);
        result = { message: responseText };
      }

      if (response.ok) {
        console.log("Success response:", result);
        alert(result.message || "Form submitted successfully");
        setFormData({
          fullName: "",
          phone: "",
          whatsapp: false,
          email: "",
          purpose: "",
        });
        toggleModal();
      } else {
        console.error("Error response:", result);
        alert(`Error: ${result.message || "Unknown error occurred"}`);
      }
    } catch (error) {
      console.error("Fetch error:", error);
      alert(`Error submitting form: ${error.message}`);
    }
  };

  const location = useLocation();
  useEffect(() => {
    if (location.state?.modal === "modal") {
      setIsModalOpen(true);
      window.history.replaceState({}, document.title);
    }
  }, [location.state?.modal]);

  return (
    <div className="w-full min-h-screen bg-gray-50 mt-[80px] pt-5">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Title */}
        <div className="text-center mb-8">
          <h1 className="text-3xl sm:text-4xl font-bold text-gray-900 mb-2 sm:mb-4">
            Our Products
          </h1>
          <p className="text-lg sm:text-xl text-gray-600 mb-6 sm:mb-12">
            Crafting experiences that travel beyond expectations.
          </p>
        </div>

        {/* Product Card */}
        <div className="rounded-lg overflow-hidden">
          {/* Product Header */}
          <div className="bg-gradient-to-r bg-blue-white backdrop-filter backdrop-blur-sm p-4 sm:p-6 ml-0 sm:ml-10">
            <div className="flex flex-row items-center justify-start gap-4 sm:gap-6">
              <img
                src={`https://99designs-blog.imgix.net/blog/wp-content/uploads/2017/12/rainbow-app-icon.png?auto=format&q=60&fit=max&w=930`}
                alt="Project-ADV Logo"
                className="w-[60px] h-[60px] sm:w-[130px] sm:h-[130px] rounded-lg shadow-lg object-cover flex-shrink-0"
              />
             <div class="flex flex-col gap-2 sm:gap-4">
  <div class="text-3xl sm:text-4xl font-bold text-gray-900 leading-tight animate-text">
    <span>P</span><span>r</span><span>o</span><span>j</span><span>e</span><span>c</span><span>t</span> <span>-</span>
    <span class="flame-text">ADV</span>
  </div>
  <button
    onClick={toggleModal}
    class="sponsor-button bg-blue-600 hover:bg-blue-600 text-white text-sm sm:text-md font-bold text-center px-3 py-1 rounded-md transition duration-300 relative overflow-hidden"
  >
    Be a Sponsor
    <span class="shine"></span>
  </button>
</div>

            </div>
          </div>

          {/* Product Content */}
          <div className="p-4 sm:p-6 w-full sm:w-[80%] ml-0 sm:ml-10">
            <p className="text-gray-900 mb-4 text-base sm:text-lg font-semibold">
              Project-A is a mobile and web application that intelligently
              recommends destinations from local to global, personalized to user
              interests and moods. Users can contribute their favorite local
              spots, expanding a global network of exploration and discovery.
            </p>

            <ul className="list-disc list-inside">
              <li className="flex items-start text-gray-900 mb-2 text-sm sm:text-base">
                <svg
                  className="w-5 h-5 mr-2 text-blue-500 flex-shrink-0 mt-1"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clipRule="evenodd"
                  />
                </svg>
                <span>
                  Provides detailed planning, smooth navigation, and real-time
                  updates and reports with safe guidelines.
                </span>
              </li>
              <li className="flex items-start text-gray-900 mb-2 text-sm sm:text-base">
                <svg
                  className="w-5 h-5 mr-2 text-blue-500 flex-shrink-0 mt-1"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clipRule="evenodd"
                  />
                </svg>
                <span>
                  Our Advanced AI suggests destinations based on mood and
                  preferences, providing thorough planning for all visits.
                </span>
              </li>
              <li className="flex items-start text-gray-900 mb-2 text-sm sm:text-base">
                <svg
                  className="w-5 h-5 mr-2 text-blue-500 flex-shrink-0 mt-1"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clipRule="evenodd"
                  />
                </svg>
                <span>
                  Enable users to share and update their favorite local
                  attractions, enriching exploration for others.
                </span>
              </li>
              <li className="flex items-start text-gray-900 mb-2 text-sm sm:text-base">
                <svg
                  className="w-5 h-5 mr-2 text-blue-500 flex-shrink-0 mt-1"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clipRule="evenodd"
                  />
                </svg>
                <span>
                  Promotes a global network of shared experiences and insights.
                </span>
              </li>
            </ul>

            <p className="text-gray-700 my-6 text-sm sm:text-base">
              Complete information will be provided prior to{" "}
              <a
                className="text-blue-600 underline hover:no-underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                Release
              </a>
            </p>
          </div>
        </div>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="w-full h-[520px] md:w-2/6 bg-white p-4 text-center text-gray-800 rounded-lg relative">
            <button
              onClick={toggleModal}
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 focus:outline-none"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            <h3 className="text-xl md:text-2xl font-bold mb-4">
              Be a Sponsor!
            </h3>
            <form
              className="flex flex-col gap-4 bg-white rounded-md p-4 md:p-8"
              onSubmit={handleSubmit}
            >
              <input
                className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                type="text"
                name="fullName"
                placeholder="Full Name"
                required
                value={formData.fullName}
                onChange={handleChange}
              />
              <input
                className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                type="tel"
                name="phone"
                placeholder="Phone"
                required
                value={formData.phone}
                onChange={handleChange}
              />
              <label className="flex items-center gap-2">
                <input
                  type="checkbox"
                  name="whatsapp"
                  className="border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                  checked={formData.whatsapp}
                  onChange={handleChange}
                />
                <span className="text-gray-700">
                  WhatsApp for the above number?
                </span>
              </label>
              <input
                className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                type="email"
                name="email"
                placeholder="Personal Email (optional)"
                value={formData.email}
                onChange={handleChange}
              />
              <textarea
                className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                name="purpose"
                placeholder="Describe yourself (optional)"
                rows="3"
                value={formData.purpose}
                onChange={handleChange}
              ></textarea>
              <button className="w-full px-3 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 focus:outline-none">
                Submit
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Products;
