import React from "react";

const Feature = ({ image, title, subtitle, description }) => (
  <div className="bg-white p-4 rounded-md border border-gray-300 flex flex-col items-center">
    <div className="flex flex-col items-center">
      <img src={image} alt="feature icon" className="h-24 w-24 mb-6" />
      <h3 className="text-2xl font-bold mb-1 text-center">{title}</h3>
      <h4 className="text-lg font-medium mb-2 text-center">{subtitle}</h4>
    </div>
    <p className="text-gray-600 text-center">{description}</p>
  </div>
);

const WhyBytehearts = () => {
  const features = [
    {
      image: "https://bytehearts-home.s3.ap-south-1.amazonaws.com/assets/digital_product.png",
      title: "ByteHearts Products",
      // subtitle: "Innovative and Creative",
      description:
        "We provide our proprietary software products, meticulously designed to enhance user experiences and streamline global connectivity. Our unique perspective drives the creation of innovative and creative solutions.",
    },
    {
      image: "https://bytehearts-home.s3.ap-south-1.amazonaws.com/assets/ai_driven.svg",
      title: "AI-Driven Excellence",
      // subtitle: "Advanced AI Models",
      description:
        "Our products, powered by AI-optimized models, deliver precise and insightful responses, ensuring user experience from every perspective. Continuously refining our AI capabilities, we stay at the forefront of technological innovation.",
    },
    {
      image: "https://bytehearts-home.s3.ap-south-1.amazonaws.com/assets/not_service.svg",
      title: "Not for Service",
      // subtitle: "Focused on Development",
      description:
        "We currently focus solely on product development, with plans to explore SaaS offerings in the future to meet evolving market needs. Our commitment remains on delivering top-notch products before expanding into services.",
    },
    {
      image: "https://bytehearts-home.s3.ap-south-1.amazonaws.com/assets/shield.png",
      title: "Secure and Reliable",
      // subtitle: "Trust and Dependability",
      description:
        "Our commitment to security and reliability ensures that our software products protect user data and perform consistently under all conditions. By adhering to the highest standards, we build trust and deliver dependable solutions to our users.",
    },
  ];

  return (
    <div id="why-bytehearts" className="pt-16 pb-20 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-4xl font-bold text-center mb-4">Why ByteHearts?</h2>
        <h4 className="text-lg font-regular text-center mb-16 text-gray-600"> Innovation dances with creativity, and reliability stands as our steadfast promise.</h4>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {features.map((feature, index) => (
            <Feature key={index} {...feature} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default WhyBytehearts;
