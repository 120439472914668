import React, { useEffect, useState } from "react";

const milestonesData = [
  {
    id: 1,
    date: "July 2024",
    description: "Launching our website to deliver exceptional user experiences, inspire creativity with our innovative products, connect users globally, and ensure top-notch data security.",
    imgSrc: "https://bytehearts-home.s3.ap-south-1.amazonaws.com/assets/bytehearts_full.png",
    logo: "https://bytehearts-home.s3.ap-south-1.amazonaws.com/assets/bytehearts_logo.png",
    bgColor: "bg-blue-500",
  },
  {
    id: 2,
    date: "October 2024",
    description: "We will launch our application in October 2024 to offer detailed planning, real-time updates, AI-driven destination suggestions, and a global network for sharing local attractions.",
    imgSrc: "https://bytehearts-home.s3.ap-south-1.amazonaws.com/assets/adv_mile.svg",
    logo: "https://bytehearts-home.s3.ap-south-1.amazonaws.com/assets/adv_sm.svg",
    bgColor: "bg-red-500",
  },
  {
    id: 3,
    bgColor: "bg-black",
  },
  {
    id: 4,
    bgColor: "bg-orange-500",
  },
  {
    id: 5,
    bgColor: "bg-pink-500",
  },
  {
    id: 6,
    bgColor: "bg-yellow-500",
  },
  {
    id: 7,
    bgColor: "bg-blue-500",
  },
];

const Milestones = () => {
  const getDefaultMilestone = () => {
    return milestonesData.find((milestone) => milestone.description) || milestonesData[milestonesData.length - 1];
  };

  const [displayedMilestone, setDisplayedMilestone] = useState(getDefaultMilestone());
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleClick = (milestone) => {
    if (milestone.description) {
      setDisplayedMilestone(milestone);
    }
  };

  return (
    <div className="flex flex-col items-center p-4 md:p-10 bg-white">
      <h1 className="text-3xl md:text-4xl font-bold mb-4 ">Milestones</h1>
      <p className="text-lg text-center text-gray-900 mb-8 md:mb-16">
        Celebrating Our Journey: Each Milestone, A Step Closer to Creative Innovation
        </p>
      <div className="relative w-full flex flex-col items-center">
        <div className="w-full md:w-full lg:3/5 xl:w-1/2 mb-6 md:mb-10">
          <div
            className={`flex flex-col md:flex-row mb-4 md:mb-8 ${
              !displayedMilestone.imgSrc
                ? "bg-gray-500 opacity-95 invisible"
                : "bg-white shadow-lg visible"
            } rounded-2xl`}
          >
            {displayedMilestone.imgSrc && (
              <img
                src={displayedMilestone.imgSrc}
                alt="Milestone Logo"
                className="w-full h-[200px] md:w-[300px] md:h-[220px] object-cover rounded-t-2xl md:rounded-l-2xl md:rounded-tr-none md:mr-4"
              />
            )}
            <div className="p-4 flex flex-col">
              <h2 className="text-2xl text-center md:text-3xl font-bold mb-2 md:mb-4">
                {displayedMilestone.date}
              </h2>
              <p className="text-gray-700">{displayedMilestone.description}</p>
            </div>
          </div>
        </div>
        <div className="w-full flex justify-center">
          <div className="relative w-full max-w-3xl px-4">
            <div className="absolute left-0 right-0 top-1/2 transform -translate-y-1/2 h-0.5 bg-gray-300"></div>
            <div className="flex justify-between items-center relative">
              {milestonesData.map((milestone) => (
                <div key={milestone.id} className="flex flex-col items-center">
                  <div
                    className={`${
                      milestone.date && milestone.description
                        ? "w-6 h-6 md:w-10 md:h-10"
                        : "w-2 h-2 md:w-2 md:h-2"
                    } ${milestone.bgColor} rounded-full flex items-center justify-center text-white shadow-md ${
                      milestone.date && milestone.description
                        ? "cursor-pointer"
                        : ""
                    }`}
                    onClick={() => handleClick(milestone)}
                  >
                    {milestone.logo && (
                      <img
                        src={milestone.logo}
                        alt=""
                        className="w-full h-full object-cover rounded-full hover:scale-125 transition-transform duration-200"
                      />
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Milestones;
