import React from "react";

// Define images and motives
const motives = [
  {
    id: 1,
    title: "Exceptional User Experiences",
    description:
      "At ByteHearts, we specialize in crafting outstanding user experiences through innovative application development. Our rigorous quality assurance practices ensure reliability and surpass user expectations, setting new standards in software excellence. Trust ByteHearts to deliver products that inspire confidence and satisfaction.",
    imageUrl:
      "https://www.syntacticsinc.com/wp-content/uploads/2020/11/4393652.jpg",
  },
  {
    id: 2,
    title: "Where Bytes Meet Hearts",
    description:
      "User Connectivity is at the heart of ByteHearts' mission. We are dedicated to connecting users globally through our innovative applications, fostering meaningful connections across borders. We prioritize inclusivity, user interaction, and community building, empowering individuals to engage authentically and cultivate enriching relationships.",
    imageUrl:
      "https://rehmatsandhufoundation.com.au/wp-content/uploads/2019/11/Mar2019-community1200x630.png",
  },
  {
    id: 3,
    title: "We Protect Your Hearts with Our Bytes",
    description:
      "Protecting user privacy and ensuring data security are at the core of our values. We employ robust measures, including advanced data encryption and strict privacy policies, to create a trusted online environment. Upholding high ethical standards and fostering a culture of integrity are integral to our company’s growth. ",
    imageUrl:
      "https://i0.wp.com/blog.xposedornot.com/wp-content/uploads/2020/08/wetakeprivacyseriously-scaled-1.jpg?fit=2560%2C1440&ssl=1",
  },
];

const OurMotives = () => {
  return (
    <div className="bg-slate-50 py-12 mt-[750px]">
      <div className="container mx-auto px-4 w-[90%]">
        <h1 className="text-4xl font-bold text-center mb-2">Our Vision</h1>
        <p className="text-lg text-center text-gray-900 mb-8">
          With every line of code, we craft experiences that resonate deeply
          with your journey.
        </p>

        {motives.map((motive, index) => (
          <div
            key={motive.id}
            className={`flex flex-col md:flex-row items-center py-8 ${
              index % 2 === 0 ? "md:flex-row-reverse" : ""
            }`}
          >
            {/* Motive Title and Description */}
            <div
              className={`w-full md:w-1/2 ${
                index % 2 === 0 ? "md:pl-8" : "md:pr-8"
              } flex flex-col justify-center`}
            >
              <h2 className="text-3xl font-bold mb-4 text-gray-900 text-start">
                {motive.title}
              </h2>
              <p className="text-lg text-gray-800 leading-relaxed">
                {motive.description}
              </p>
            </div>

            {/* Image Container */}
            <div
              className={`w-full md:w-1/2 ${
                index % 2 === 1 ? "md:pr-8 flex justify-end" : ""
              }`}
            >
              <img
                src={motive.imageUrl}
                alt={`Image ${motive.id}`}
                className="w-[400px] h-[250px] rounded-lg shadow-md object-cover"
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OurMotives;
