import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../App.css";

function Header({ scrolled }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [currentroute, setCurrentRoute] = useState();

  useEffect(() => {
    const controlNavbar = () => {
      if (typeof window !== "undefined") {
        if (window.scrollY > lastScrollY) {
          setIsVisible(false);
        } else {
          setIsVisible(true);
        }
        setLastScrollY(window.scrollY);
      }
    };

    if (typeof window !== "undefined") {
      window.addEventListener("scroll", controlNavbar);
      return () => {
        window.removeEventListener("scroll", controlNavbar);
      };
    }
  }, [lastScrollY]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setCurrentRoute(location.pathname);
  }, [location]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header
      className={`transition-all duration-700 fixed top-0 left-0 right-0 z-40 text-white
        ${
          !scrolled &&
          window.scrollY <= 750 &&
          currentroute !== "/joinus" &&
          currentroute !== "/aboutus" &&
          currentroute !== "/products"
            ? "bg-transparent"
            : "bg-[#314bc2] bg-opacity-85 backdrop-filter backdrop-blur-md "
        }
        ${
          isVisible
            ? "translate-y-0 opacity-100"
            : "-translate-y-full opacity-0"
        }`}
    >
      <div className="container mx-auto flex justify-between items-center pt-4 pb-6 px-6 transition-opacity duration-700">
        <div
          className="flex gap-[2px] justify-center items-center cursor-pointer sm:ml-16 md:ml-20 ml-0"
          onClick={() => {
            setIsMenuOpen(false);
            navigate("/");
          }}
        >
          <svg
            width="40"
            height="40"
            viewBox="0 0 143 144"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="transition-transform duration-300 md:w-[40px] md:h-[40px]"
          >
            <path
              d="M31 36V111C20.6 115.4 2 103 1.5 95V36C13.9 -5.19999 46 -1.49999 60.5 5.50001C37.7 4.70001 31.3333 25.5 31 36Z"
              fill="white"
              stroke="white"
            />
            <path
              d="M21.0004 114.5C17.5004 114.5 1.50039 108 1.50039 103C-3.29961 129.8 15.8337 140.833 26.0004 143H106.5C125.3 140.2 135.334 126.167 138 119.5C138.8 99.5 120.667 93.8333 111.5 93.5C117 107 108.667 111 104.5 114.5H21.0004Z"
              fill="white"
              stroke="white"
            />
            <path
              d="M66.5 8.50002C47.5 5 38.1667 22 35.5 31C47.5 27.8 54.1667 34 56 37.5V88.5H106.5C128.9 88.9 139.5 103.667 142 111C142.8 71.8 118.667 60.3334 106.5 59.5H85V36.5C81.4 22.1 71.5 10.5 66.5 8.50002Z"
              fill="white"
              stroke="white"
            />
          </svg>
          <div className="text-2xl md:text-4xl font-bold mt-2 transition-opacity duration-700">
            yteHearts
          </div>
        </div>

        {/* Menu Icon (visible only on smaller screens) */}
        <div
          className="cursor-pointer md:hidden rounded-lg "
          onClick={toggleMenu}
        >
          {!isMenuOpen ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-8 w-8"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 6h16M4 12h16m-7 6h7"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-8 w-8"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          )}
        </div>

        {/* Navigation for larger screens */}
        <nav className="hidden md:flex mt-4 space-x-8 font-semibold mr-20">
          <div
            onClick={() => navigate("/aboutus")}
            className="text-2xl cursor-pointer relative group"
          >
            <span className="transition-colors duration-300 group-hover:text-blue-400">
              About Us
            </span>
            <span className="absolute -bottom-1 left-0 w-0 h-0.5 bg-blue-500 transition-all duration-300 group-hover:w-full"></span>
          </div>
          <div
            onClick={() => navigate("/products")}
            className="text-2xl cursor-pointer relative group"
          >
            <span className="transition-colors duration-300 group-hover:text-blue-500">
              Products
            </span>
            <span className="absolute -bottom-1 left-0 w-0 h-0.5 bg-blue-500 transition-all duration-300 group-hover:w-full"></span>
          </div>
          <div
            onClick={() => navigate("/joinus")}
            className="text-2xl cursor-pointer relative group"
          >
            <span className="transition-colors duration-300 group-hover:text-blue-500">
              Join Us
            </span>
            <span className="absolute -bottom-1 left-0 w-0 h-0.5 bg-blue-500 transition-all duration-300 group-hover:w-full"></span>
          </div>
        </nav>
      </div>

      {/* Navigation Menu for smaller screens */}
      {isMenuOpen && (
        <div
          className={`absolute ${
            location.pathname === "/"
              ? "bg-blue-500 opacity-100 backdrop-blur-md backdrop-filter"
              : "bg-blue-500"
          }  w-screen inset-0 h-screen z-50 md:hidden`}
        >
          <div className="flex flex-col text-white items-center gap-2 space-y-6 mt-8 flex-item">
            <div
              onClick={() => {
                toggleMenu();
              }}
              className="text-2xl cursor-pointer self-end mr-5 mb-1  hover:underline transition-colors duration-300"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-8 w-8"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </div>
            <div
              onClick={() => {
                navigate("/aboutus");
                toggleMenu();
              }}
              className="text-2xl cursor-pointer text-left hover:underline transition-colors duration-300"
            >
              About Us
            </div>
            <div
              onClick={() => {
                navigate("/products");
                toggleMenu();
              }}
              className="text-2xl cursor-pointer text-left hover:underline transition-colors duration-300"
            >
              Products
            </div>
            <div
              onClick={() => {
                navigate("/joinus");
                toggleMenu();
              }}
              className="text-2xl cursor-pointer text-left hover:underline transition-colors duration-300 pb-4"
            >
              Join Us
            </div>
          </div>
        </div>
      )}
    </header>
  );
}

export default Header;