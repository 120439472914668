import React from "react";
import "../App.css";

const Mainpage = () => {
  const imageCount = 36;

  return (
    <div className="home-bg h-screen ">
      <div className="home-bg-image-list">
        <div className="home-bg-image-list-rotate">
          {[...Array(imageCount)].map((_, index) => (
            <div
              key={index}
              className="home-bg-image-item"
              style={{
                animationDelay: `${(index * -18) / imageCount}s`,
              }}
            >
              <picture className="home-bg-image">
                <img
                  src={`https://bytehearts-home.s3.ap-south-1.amazonaws.com/WebScroll/img_${
                    index + 1
                  }.jpg`}
                  alt={`cover ${index + 1}`}
                />
              </picture>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Mainpage;
