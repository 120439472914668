import React from "react";
import { useNavigate } from "react-router-dom";

function MainContent() {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col gap-4 justify-center items-center absolute z-30 left-0 right-0 sm:right-[100px] sm:left-auto md:right-[200px] top-0 bottom-[30px] sm:w-[500px]">
      <div className="sm:text-8xl text-7xl font-bold mt-4 text-white mx-4 sm:mx-0">
        ByteHearts
      </div>
      <p className="sm:text-gray-300 text-white text-base font-semibold text-center mt-4 mx-4 sm:mx-0">
        We create exceptional user experiences, connect users globally, and
        ensure top-notch data security. Join us in setting new standards in
        software excellence and community building.
      </p>
      <div className="flex gap-4 mt-4 mx-4 sm:mx-0">
        <button
          className="bg-white text-black font-semibold py-3 px-6 rounded-3xl "
          onClick={() => {
            navigate("/products");
          }}
        >
          Our Products
        </button>
        <button
          className="border border-white text-white font-semibold py-3 px-6 rounded-3xl"
          onClick={() => {
            navigate("/joinus");
          }}
        >
          Join the Journey
        </button>
      </div>
    </div>
  );
}

export default MainContent;
