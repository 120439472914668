// src/components/FAQ.js
import React, { useState } from "react";

const FAQItem = ({ question, answer, isOpen, onClick }) => {
  return (
    <div className="mb-8 w-full">
      <div className="bg-[#f3f3ff] rounded-lg overflow-hidden">
        <button
          className="w-full text-left p-4 focus:outline-none hover:text-blue text-lg font-semibold flex justify-between items-center px-5 py-7"
          onClick={onClick}
        >
          <span className="pr-4">{question}</span>
          <svg
            className={`w-6 h-6 flex-shrink-0 transition-transform duration-300 ${
              isOpen ? "transform rotate-180" : ""
            }`}
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M19 9l-7 7-7-7"
            />
          </svg>
        </button>
        <div
          className={`transition-all duration-500 ease-in-out overflow-hidden ${
            isOpen ? "max-h-[500px] opacity-100" : "max-h-0 opacity-0"
          }`}
        >
          <div className="p-4 bg-[#fcfdff]">
            <p>{answer}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const FAQ = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const faqData = [
    {
      question: "What products is ByteHearts developing?",
      answer:
        "At ByteHearts, we are dedicated to creating innovative software solutions. Currently, we are developing Project-ADV, which is designed to recommend places based on user mood and interests. This product is set to launch in October 2024.",
    },    
    {
      question: "How do you ensure the security and quality of your products?",
      answer:
        "We focus on protecting user privacy and security. We use encryption to keep data safe, limit access to authorized personnel, and regularly check our systems for vulnerabilities. Additionally, we educate our users about potential threats like phishing to help them stay safe. Our commitment to quality includes thorough testing and user feedback to continually improve our products.",
    },
    {
      question:
        "What services does ByteHearts provide?",
      answer:
        "Currently, ByteHearts does not offer external services, as our primary focus is on developing our own software products. We are dedicated to creating innovative solutions that enhance user experiences. In the future, we plan to explore offering SaaS services tailored to meet specific business needs as we grow and adapt to market demands.",
    },
    {
      question: "What is Project-ADV and what is its purpose?",
      answer:
        "Project-ADV is designed to recommend places locally and globally based on the user's mood and interests. It helps users discover what they truly want from nearby options and connects them to those experiences. For more information, please visit our product page.",
    },
    {
      question: "How does ByteHearts gather user feedback for its products?",
      answer:
        " At ByteHearts, we value user feedback as a crucial part of our development process. We gather insights through surveys, direct user interactions, and beta testing phases. This feedback helps us understand user needs and preferences, enabling us to enhance our products and deliver a better experience.",
    },
    {
      question: "What is ByteHearts' vision for the future?",
      answer:
        `ByteHearts envisions becoming a leader in innovative software solutions that connect users globally. We aim to continuously improve our products based on user feedback and emerging technologies, and we plan to expand our offerings to include SaaS solutions that address specific market needs, fostering greater engagement and user satisfaction.`,
    },
  ];

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const halfIndex = Math.ceil(faqData.length / 2);
  const leftFAQs = faqData.slice(0, halfIndex);
  const rightFAQs = faqData.slice(halfIndex);

  return (
    <div className="w-full mx-auto px-4 py-8 bg-slate-50">
      <h2 className="text-3xl font-bold text-center mb-4 mt-16">
        Frequently Asked Questions
      </h2>
      <h4 className="text-lg font-regular text-center mb-24 text-gray-600">Curious hearts, don’t hesitate. Discover answers that await!</h4>
      <div className="md:flex md:justify-between md:mx-10 my-8">
        <div className="flex flex-col w-full md:w-[47%]">
          {leftFAQs.map((faq, index) => (
            <FAQItem
              key={index}
              question={faq.question}
              answer={faq.answer}
              isOpen={openIndex === index}
              onClick={() => handleToggle(index)}
            />
          ))}
        </div>
        <div className="flex flex-col w-full md:w-[47%]">
          {rightFAQs.map((faq, index) => (
            <FAQItem
              key={halfIndex + index}
              question={faq.question}
              answer={faq.answer}
              isOpen={openIndex === halfIndex + index}
              onClick={() => handleToggle(halfIndex + index)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQ;
