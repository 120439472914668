import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";

function JoinUs() {
  const [formData, setFormData] = useState({
    fullName: "",
    phone: "",
    whatsapp: false,
    email: "",
    purpose: "",
    timestamp: new Date().toISOString(),
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const submissionData = {
      type: "join us",
      fullName: formData.fullName,
      phone: formData.phone,
      whatsapp: formData.whatsapp,
      email: formData.email || null,
      purpose: formData.purpose,
    };

    console.log("Submitting data:", submissionData);

    try {
      console.log(
        "Sending request to:",
        "https://abo7g4oez1.execute-api.ap-south-1.amazonaws.com/Prod/join"
      );
      const response = await fetch(
        "https://abo7g4oez1.execute-api.ap-south-1.amazonaws.com/Prod/join",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(submissionData),
        }
      );

      console.log("Response status:", response.status);
      console.log(
        "Response headers:",
        Object.fromEntries(response.headers.entries())
      );

      const responseText = await response.text();
      console.log("Raw response:", responseText);

      let result;
      try {
        result = JSON.parse(responseText);
      } catch (parseError) {
        console.error("Error parsing response:", parseError);
        result = { message: responseText };
      }

      if (response.ok) {
        console.log("Success response:", result);
        alert(result.message || "Form submitted successfully");
        setFormData({
          fullName: "",
          phone: "",
          whatsapp: false,
          email: "",
          purpose: "",
        });
      } else {
        console.error("Error response:", result);
        alert(`Error: ${result.message || "Unknown error occurred"}`);
      }
    } catch (error) {
      console.error("Fetch error:", error);
      alert(`Error submitting form: ${error.message}`);
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-900 text-white mt-[80px] pb-[66px]">
      <div className="flex-1 flex-col md:flex-row flex justify-between p-4 md:p-4 lg:p-8 mx-auto max-w-7xl">
        <div className="w-full md:w-1/2 mb-8 md:mb-0">
          <h1 className="text-2xl md:text-3xl font-bold mb-6 md:mb-14 text-blue-500">
            Join ByteHearts' journey
          </h1>
          <h2 className="text-3xl md:text-4xl font-bold mb-4 md:mb-6">
            Our products craft personalized paths to productivity, tailored for
            our users' journeys.
          </h2>
          <p className="mb-4 md:mb-6">
            ByteHearts specializes in delivering innovative products that
            provide rich personalized experiences through advanced technologies
            and forward-thinking innovation.
          </p>
          <ul className="space-y-2">
            <li className="flex items-center">
              <svg
                className="w-5 h-5 mr-2 text-blue-500"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clipRule="evenodd"
                />
              </svg>
              Product ideas are perfectly aligned with users to thrive in this
              new world
            </li>
            <li className="flex items-center">
              <svg
                className="w-5 h-5 mr-2 text-blue-500"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clipRule="evenodd"
                />
              </svg>
              Flexible recommendation models to accommodate specific user
              scenarios and goals
            </li>
            <li className="flex items-center">
              <svg
                className="w-5 h-5 mr-2 text-blue-500"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clipRule="evenodd"
                />
              </svg>
              Security and privacy are safeguarded with byte-level encryption
            </li>
          </ul>
        </div>

        <div className="w-full h-[520px] md:w-2/5 bg-white p-4 text-center text-gray-800 rounded-lg">
          <h3 className="text-xl md:text-2xl font-bold mb-4 ">Join with us!</h3>
          <form
            className="flex flex-col gap-4 bg-white rounded-md p-4 md:p-8"
            onSubmit={handleSubmit}
          >
            <input
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
              type="text"
              name="fullName"
              placeholder="Full Name"
              required
              value={formData.fullName}
              onChange={handleChange}
            />
            <input
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
              type="tel"
              name="phone"
              placeholder="Phone"
              required
              value={formData.phone}
              onChange={handleChange}
            />
            <label className="flex items-center gap-2">
              <input
                type="checkbox"
                name="whatsapp"
                className="border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                checked={formData.whatsapp}
                onChange={handleChange}
              />
              <span className="text-gray-700">
                WhatsApp for the above number?
              </span>
            </label>
            <input
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
              type="email"
              name="email"
              placeholder="Personal Email (optional)"
              value={formData.email}
              onChange={handleChange}
            />
            <textarea
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
              name="purpose"
              placeholder="Purpose for connecting?"
              rows="3"
              value={formData.purpose}
              onChange={handleChange}
            ></textarea>
            <button className="w-full px-3 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 focus:outline-none">
              Join Us
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default JoinUs;
